import { Controller } from "@hotwired/stimulus"
import "chartkick/chart.js"
import {ChartHelpers} from "../widgets/chart_helpers";

export default class extends Controller {
  static values = { url: String, source: String, id: String, selectCssClass: String, resetDropdownsTrigger: String, resetDropdownsTargets: String }
  
  async reset() {
    if (!this.urlValue) return;
    const data = await this.fetchNewData()
    this.updateChart(data.chart_data)
  }

  async resetOnSelectADropdownOption(event) {
    this.urlValue = event.target.value
    const dropdowns = document.getElementsByClassName(this.selectCssClassValue)
    const data = await this.fetchNewData()
    this.updateDropdowns(dropdowns, data.filters_data)
    this.updateChart(data.chart_data)
  }

  fetchNewData = () => {
    return fetch(this.urlValue).then(response => {return response.json()})
  }

  updateChart = (chart_data) => {
    let chart = Chartkick.charts[this.idValue]
    chart.updateData(JSON.parse(chart_data))
  }

  updateDropdowns(dropdowns, filters_data) {
    const chartHelper = new ChartHelpers(this.sourceValue, this.idValue)
    let optionsData = {}

    Object.keys(filters_data).forEach((filterName) => {
      const options = filters_data[filterName]
      Object.keys(options).forEach((option) => {
        const params = chartHelper.constructParams(filters_data);
        if (filterName === this.resetDropdownsTriggerValue) {
          params.set('reset', this.resetDropdownsTargetsValue)
        }
        params.set(filterName, option);
        const path = `${this.sourceValue}?${params.toString()}`
        optionsData [options[option].css_id] = { path: path, displayed: options[option].displayed, selected: options[option].selected }
      });
    })

    for (let dropdown of dropdowns) {
      for (let option of dropdown.options) {
        option.value = optionsData[option.id].path
        if (optionsData[option.id].displayed === true) {
          option.classList.remove('d-none');
        } else {
          option.classList.add('d-none')
        }
        if (optionsData[option.id].selected) {
          option.setAttribute('selected', true);
        } else {
          option.removeAttribute('selected')
        }
      }
    }
  }

  disconnect(){
  }
}
