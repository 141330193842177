import { Widget } from './widget'

export class TextWidget extends Widget {
    constructor(x, y, w, h, source) {
        super(x, y, w, h, source);
    }

    content() {
        let html = this.wrapper(this.data.text, false);
        return html;
    }
}