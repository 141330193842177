import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="array-input"
export default class extends Controller {
  static targets = ['inputGroup', 'input']
  static values = { containerId: String }

  remove() {
    this.inputGroupTarget.classList.add('d-none')
    this.inputTarget.value = ''
  }

  add() {
    const inputContainer = $(`#${this.containerIdValue}`)
    const newChild = inputContainer.children().first().clone()
    newChild.removeClass('d-none')
    newChild.insertAfter(inputContainer.children().last())
  }
}
