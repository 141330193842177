export class ChartHelpers {
  constructor(source, chartId) {
    this.source = source;
    this.chartId = chartId;
  }

  dropdown(filterName, filtersData, selectCssClass, reset = {triggerFilter: "", filtersToBeReset: ""}) {
    const dropdownLabel = filterName.replaceAll('_', ' ')

    let optionElements = ``
    const options = filtersData[filterName]
    let params = this.constructParams(filtersData)
    if (reset.filtersToBeReset !== '') {
      params.set('reset', reset.filtersToBeReset)
    }
    Object.keys(options).forEach((option) => {
      params.set(filterName, option)
      let path =  `${this.source}?${params.toString()}`
      let selected = options[option].selected ? 'selected' : ''
      let displayed = options[option].displayed ? '' : 'd-none'
      optionElements += `<option id="${options[option].css_id}" class="${displayed}" value="${path}" ${selected}>${options[option].text}</option>`
    });

    return `
    <div class="form-check form-check-inline" data-controller="update-cartesian-chart-data"
         data-update-cartesian-chart-data-select-css-class-value="${selectCssClass}"
         data-update-cartesian-chart-data-reset-dropdowns-trigger-value="${reset.triggerFilter}"
         data-update-cartesian-chart-data-reset-dropdowns-targets-value="${reset.filtersToBeReset}"
         data-update-cartesian-chart-data-source-value="${this.source}"
         data-update-cartesian-chart-data-id-value="${this.chartId}">
      <label class="form-label text-capitalize">${dropdownLabel}</label>
      <select class="form-select ${selectCssClass}"
              data-action="change->update-cartesian-chart-data#resetOnSelectADropdownOption">
        ${optionElements}
      </select>
    </div>
    `
  }

  constructParams(filtersData) {
    let params = new URLSearchParams()
    Object.keys(filtersData).forEach((filterName) => {
      const selectedOption = this.findSelectedOption(filtersData[filterName])
      if (selectedOption === undefined) {
        params.delete(filterName)
      } else {
        params.set(filterName, selectedOption);
      }
    })
    return params
  }

  findSelectedOption(options) {
    return Object.keys(options).find((option) => options[option].selected === true)
  }
}
