import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="custom-select"
export default class extends Controller {
  static targets = ['select', 'input']
  static classes = ['noDisplay']

  connect() {
    this.hideSelectInput()
  }

  selectFirstSelectOption() {
    this.selectTarget.value = this.selectTarget[0].value
  }

  selectFirstCheckboxInput() {
    this.inputTargets[0].checked = true
  }

  clearSelectInput() {
    this.selectTarget.value = null
  }

  clearCheckboxInput() {
    this.inputTargets.forEach(input => {
      input.checked = false
    })
  }

  hideSelectInput() {
    this.selectTarget.classList.add(this.noDisplayClass)
  }

  showSelectInput() {
    this.selectTarget.classList.remove(this.noDisplayClass)
  }
}
