import {Widget} from './widget'

export class ChartWidget extends Widget {
    constructor(x, y, w, h, source) {
        super(x, y, w, h, source);
    }

    content() {
        let html = this.wrapper(`
            <div id="${this.data.id}" class="chart-widget h-75"></div>
        `, true);
        return html;
    }
}