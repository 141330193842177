import {ErrorWidget} from "./error_widget";

export async function create_widget(x, y, w, h, source, class_type, grid) {
    try {
        const url = window.location.origin + source;
        const widget = new class_type(x, y, w, h, url);
        await widget.fetch_data();
        grid.addWidget({
            content: widget.content(),
            h: widget.h,
            w: widget.w,
            x: widget.x,
            y: widget.y
        });
        return widget;
    } catch (error) {
        console.log(error);
        const error_widget = new ErrorWidget(x, y, w, h);
        grid.addWidget({
            content: error_widget.content(),
            h: error_widget.h,
            w: error_widget.w,
            x: error_widget.x,
            y: error_widget.y
        });
        return error_widget;
    }
};

export async function create_chart_widget(x, y, w, h, source, class_type, grid, chart_type) {
    const widget = await create_widget(x, y, w, h, source, class_type, grid);
    if (!(widget instanceof ErrorWidget)) {
        switch(chart_type) {
            case 'line_chart':
                new Chartkick.LineChart(widget.data.id, JSON.parse(widget.data.chart_data));
                break;
            case 'pie_chart':
                new Chartkick.PieChart(widget.data.id, JSON.parse(widget.data.chart_data));
                break;
            case 'column_chart':
                new Chartkick.ColumnChart(widget.data.id, JSON.parse(widget.data.chart_data));
                break;
        };
    }
    return widget;
};
