import {Controller} from "@hotwired/stimulus"

// the controller works by storing menu open/closed state in HTML5 localstorage
// if a menu is clicked open, the id of the menu item is put in localstorage as a key
// if a menu is closed, the id is removed from localstorage
// the actual value for the stored keys is not important, if the key exists, the menu should be open
export default class extends Controller {

    connect() {

        // make a list of all parent menu items
        let allMenuItems = $(".sidebar-menu-item").map(function() {return this.id;}).get();

        // go through list of menu items, and if a menu item is found in localstorage, open it
        for (const menuItemID of allMenuItems) {

            let menuItem = $(`#${menuItemID}`);

            let menuState = localStorage.getItem(menuItemID);
            if (menuState) {
                this.openMenuItem(menuItem);
                continue;
            }

            // special case, if we're on a page that is a child of this menu item, the parent menu item should be open
            let expr = $(menuItem).data("path-regex");
            if ((new RegExp(expr)).test(window.location.href)) {
                localStorage.setItem(menuItemID, "true");
                this.openMenuItem(menuItem);
                continue;
            }

            this.closeMenuItem(menuItem);
        }

        // callback that removes menu item from localstorage when menu is closed
        let toggleMenuItem = this.toggleMenuItem; // put toggleMenuItem in the closure because "this" will not be available in the click callback
        $(".sidebar-menu-item > a").click(function() {

            // if we're opening a menu item, add the id to localstorage
            // if we're closing a menu item, remove the id from localstorage
            let clickedItemID = $(this).parent().attr("id");
            if($(this).find(".menu-arrow").hasClass("bi-chevron-down")) {
                localStorage.setItem(clickedItemID, "true");
            } else {
                localStorage.removeItem(clickedItemID)
            }

            // toggle the collapse state and arrow
            toggleMenuItem($(this).parent(".sidebar-menu-item"))

        });
    }

    openMenuItem(menuItem) {
        let arrowEl = $(menuItem).find(".menu-arrow");
        $(arrowEl).removeClass("bi-chevron-down")
        $(arrowEl).addClass("bi-chevron-up");
        $(menuItem).find("ul").show();
    }

    closeMenuItem(menuItem) {
        let arrowEl = $(menuItem).find(".menu-arrow");
        $(arrowEl).removeClass("bi-chevron-up")
        $(arrowEl).addClass("bi-chevron-down");
        $(menuItem).find("ul").hide();
    }

    toggleMenuItem(menuItem) {
        $(menuItem).children("ul").slideToggle("100");
        $(menuItem).find(".menu-arrow").toggleClass("bi-chevron-up bi-chevron-down");
    }

}