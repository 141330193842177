import {ChartHelpers} from "./chart_helpers";

export class ApiStatsChartHelpers extends ChartHelpers {
  constructor(source, chartId) {
    super(source, chartId);
  }

  filterByEventNameButton(eventName, buttonText, checked = '') {
    const path = eventName == null ? this.source : `${this.source}?event_name=${eventName}`
    return `
    <div class="form-check form-check-inline" data-controller="update-cartesian-chart-data"
         data-update-cartesian-chart-data-url-value="${path}"
         data-update-cartesian-chart-data-id-value="${this.chartId}">
      <input class="form-check-input" type="radio" name="apiStatsChartOptions" ${checked}
             data-action="click->update-cartesian-chart-data#reset">
      <label class="form-check-label">${buttonText}</label>
    </div>
    `
  }
}
