import { Widget } from './widget'

export class ParticipationsLastWidget extends Widget {
  constructor(x, y, w, h, source) {
    super(x, y, w, h, source);
  }

  content() {
    let text = ''
    text += ` <div id="carouselLatestParticipations" class="carousel carousel-dark slide" data-bs-ride="carousel">`;

    text += `<div class="carousel-indicators">`
    for (let i = 0; i < this.data.participations.length; ++i) {
      text += `<button type="button" data-bs-target="#carouselLatestParticipations" data-bs-slide-to="${i}" class="active" aria-current="true" aria-label="Slide ${(i + 1) % this.data.participations.length}"></button>`
    }
    text += `</div>`;

    text += `<div class="carousel-inner">`;
    for (let i = 0; i < this.data.participations.length; ++i) {
      text += ` <div class="carousel-item ${(i == 0) ? 'active' : ''} "> 
                <div class="row justify-content-center">
                  <ul class="list-group d-block w-75">`

      for (const [field, values] of Object.entries(this.data.participations[i])) {
        if (field == 'link') {
          text += ` 
                    <a class="list-group-item bg-light" href="${values.value}" target="_blank">
                      <div class="d-flex justify-content-center px-3">
                        ${values.name}
                      </div>
                    </a>`
          continue
        }
        text += ` 
                  <li class="list-group-item bg-light">
                    <div class="d-flex justify-content-between px-3">
                      <span>${values.name}</span>
                      <span class="fw-bold">${values.value ?? '-'}</span>
                    </div>
                  </li>`
      }
      text += `</ul></div></div>`
    }

    text += '</div>'

    text += `<button class="carousel-control-prev" type="button" data-bs-target="#carouselLatestParticipations" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselLatestParticipations" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>`

    text += '</div>'
    let html = this.wrapper(text, true);
    return html;
  }
}
