import { Widget } from './widget'

export class CountWidget extends Widget {
    constructor(x, y, w, h, source) {
        super(x, y, w, h, source);
    }

    content() {
        let html = `<div class="row"><div class="col"><h5 class="text-uppercase text-muted mb-0">${this.data.text}</h5></div><div class="col-auto"><div class="icon"><i class="bi bi-person-fill"></i></div></div></div><div class="row mt-3 ml-3"><span class="h2 font-weight-bold mb-0">${this.data.count}</span></div>`
        html = this.wrapper(html, false);
        return html;
    }
}