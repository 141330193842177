import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input-alias"
export default class extends Controller {

  static targets = ["root"]

  addAlias({ detail: { content } }) {
    let name = content['name']
    this.rootTarget.innerHTML += ` <div class="ar-table-row">
                                        <div class="ar-table-cell">
                                            <input class="form-check-input" type="checkbox" checked="checked" name="aliases[]" id="company_alias_${name}" value="${name}">
                                            <span> ${name} </span>
                                        </div>
                                      </div>
                                    `
    let aliases = content['aliases']
    for (let i = 0; i < aliases.length; ++i) {
      let alias = aliases[i]
      this.rootTarget.innerHTML += ` <div class="ar-table-row">
                                        <div class="ar-table-cell">
                                            <input class="form-check-input" type="checkbox" checked="checked" name="aliases[]" id="company_alias_${alias}" value="${alias}">
                                            <span> ${alias} </span>
                                        </div>
                                      </div>
                                    `
    }
  }
}