import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sort"
export default class extends Controller {
  static targets = [ "column", "direction" ]

  sort(e) {
    const url = new URL(e.target.href)
    const urlParams = new URLSearchParams(url.search)
    this.columnTarget.value = urlParams.get('search[column]')
    this.directionTarget.value = urlParams.get('search[direction]')
  }
}
