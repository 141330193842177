import { Widget } from './widget'

export class ErrorWidget extends Widget {
    constructor(x, y, w, h) {
        super(x, y, w, h, '');
    }

    content() {
        let html = this.wrapper(`<p class='text-danger fw-lighter fst-italic'>Failed to load widget</p>`, false);
        return html;
    }
}