import {Widget} from './widget'
import {ApiStatsChartHelpers} from "./api_stats_chart_helpers";

export class ApiStatsChartWidget extends Widget {
    constructor(x, y, w, h, source) {
        super(x, y, w, h, source);
    }

    content() {
        const helpers = new ApiStatsChartHelpers(this.source, this.data.id)
        let html = this.wrapper(`
            <div class="d-flex my-3 justify-content-end">
              ${helpers.filterByEventNameButton(null, 'All', 'checked')}
              ${helpers.filterByEventNameButton('questionnaire_index', 'Fetch All Questionnaires')}
              ${helpers.filterByEventNameButton('questionnaire_show', 'Fetch A Questionnaire')}
              ${helpers.filterByEventNameButton('participation_create', 'Create Participation')}
              ${helpers.filterByEventNameButton('participation_show', 'Fetch A Participation')}
            </div>
            <div id="${this.data.id}" class="chart-widget h-75"></div>
        `, true);
        return html;
    }
}
