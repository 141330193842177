import {Widget} from './widget'
import {ParticipationsChartHelpers} from "./participations_chart_helpers";

export class ParticipationsChartWidget extends Widget {
    constructor(x, y, w, h, source) {
        super(x, y, w, h, source);
    }

    content() {
        const helpers = new ParticipationsChartHelpers(this.source, this.data.id)
        let html = this.wrapper(`
            <div class="d-flex my-3 justify-content-end">
              ${helpers.groupByButton(null, 'All', 'checked')}
              ${helpers.groupByButton('questionnaire', 'Questionnaire')}
              ${helpers.groupByButton('company', 'Company')}
              ${helpers.groupByButton('client_application', 'Client application')}
            </div>
            <div id="${this.data.id}" class="chart-widget h-75"></div>
        `, true);
        return html;
    }
}
