import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="group-report"
export default class extends Controller {
  static targets = [ "form" ]
  static values = { url: String }
  search() {
    const fullUrl = `${this.urlValue}?${this.queryValue()}`
    fetch(fullUrl, { headers: { Accept: 'text/vnd.turbo-stream.html' } })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html));
  }

  queryValue() {
    const formId = this.formTarget.id
    let form = $(`#${formId}`);
    let queries = form.serializeArray()
    const params = new URLSearchParams()
    queries.forEach(query => {
      if (query['value'] !== '') params.append(query['name'], query['value'])
    })
    return params.toString()
  }
}
