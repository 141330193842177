import {ChartHelpers} from "./chart_helpers";

export class ParticipationsChartHelpers extends ChartHelpers {
  constructor(source, chartId) {
    super(source, chartId);
  }

  groupByButton(groupByParam, buttonText, checked = '') {
    const path = groupByParam == null ? this.source : `${this.source}?group_by=${groupByParam}`
    return `
    <div class="form-check form-check-inline" data-controller="update-cartesian-chart-data"
         data-update-cartesian-chart-data-url-value="${path}"
         data-update-cartesian-chart-data-id-value="${this.chartId}">
      <input class="form-check-input" type="radio" name="participationPerDateChartOptions" ${checked}
             data-action="click->update-cartesian-chart-data#reset">
      <label class="form-check-label">${buttonText}</label>
    </div>
    `
  }
}
