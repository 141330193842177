import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="merge-participants"
export default class extends Controller {

  static targets = ["header1", "header2", "header3", "header4", "participant1", "participant2"]
  static classes = ["completeSection", "collapsed"]

  connect() {
    let collapseOne = document.getElementById('collapseOne')
    this.bsCollapseOne = new bootstrap.Collapse(collapseOne, { toggle: false })
    let collapseTwo = document.getElementById('collapseTwo')
    this.bsCollapseTwo = new bootstrap.Collapse(collapseTwo, { toggle: false })
    let collapseThree = document.getElementById('collapseThree')
    this.bsCollapseThree = new bootstrap.Collapse(collapseThree, { toggle: false })
    let collapseFour = document.getElementById('collapseFour')
    this.bsCollapseFour = new bootstrap.Collapse(collapseFour, { toggle: false })
  }

  selectparticipant1(event) {
    this.participantOne = fetch(`/data/participants/${event.target.value}`, { headers: { accept: "application/json" } })
      .then(response => response.json())
      .then((data) => {
        this.header1Target.classList.add(...this.completeSectionClasses)
        this.participant1Target.value = data['id']
        this.dispatch('selectParticipantOne', { detail: { content: data } })
      })
    this.header1Target.classList.add(this.collapsedClass)
    this.bsCollapseOne.hide()
    this.header2Target.classList.remove(this.collapsedClass)
    this.bsCollapseTwo.show()
    this.header3Target.classList.add(this.collapsedClass)
    this.bsCollapseThree.hide()
    this.header4Target.classList.add(this.collapsedClass)
    this.bsCollapseFour.hide()
  }

  selectparticipant2(event) {
    fetch(`/data/participants/${event.target.value}`, { headers: { accept: "application/json" } })
      .then(response => response.json())
      .then((data) => {
        this.header2Target.classList.add(...this.completeSectionClasses)
        this.participant2Target.value = data['id']
        this.dispatch('selectParticipantTwo', { detail: { content: data } })
      }
      )
    this.header1Target.classList.add(this.collapsedClass)
    this.bsCollapseOne.hide()
    this.header2Target.classList.add(this.collapsedClass)
    this.bsCollapseTwo.hide()
    this.header3Target.classList.remove(this.collapsedClass)
    this.bsCollapseThree.show()
    this.header4Target.classList.remove(this.collapsedClass)
    this.bsCollapseFour.show()
  }
}
