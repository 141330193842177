import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-participations"
export default class extends Controller {

  static targets = ["list", "input", "listParent", "warning", "showReportButton"]

  addParticipation({ detail: { content } }) {
    if (this.hasWarningTarget) {
      this.warningTarget.classList.add('d-none')
    }
    this.inputTargets.forEach(input => input.value === content.participation['id'])
    for (const input of this.inputTargets) {
      if (input.value === content.participation['id']) {
        return
      }
    }

    this.listTargets.at(-1).innerHTML += `<li class='list-group-item w-25 d-flex justify-content-between' >
    <span>${content.participation['identifier']}</span>
    <button type="button" class="btn-close" aria-label="Close" data-action="click->select-participations#removeParticipation"></button>
    <input type="hidden" value="${content.participation['id']}" name="participations[]" data-select-participations-target="input"></input>
    </li>`

    if (this.inputTargets.length % 4 === 0) {
      this.listParentTarget.innerHTML += `<ul class="list-group list-group-horizontal" data-select-participations-target="list"></ul>`
    }
    this.showReportButtonTarget.disabled = false
  }

  removeParticipation(event) {
    event.target.parentElement.remove()
    if (this.inputTargets.length === 0) {
      this.warningTarget.classList.remove('d-none')
      this.showReportButtonTarget.disabled = true
    }
  }
}
