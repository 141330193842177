import { Controller } from "@hotwired/stimulus"
import 'gridstack/dist/gridstack.min.css';
import { GridStack } from "gridstack";
import { ImageWidget } from "../widgets/image_widget";
import { TextWidget } from "../widgets/text_widget";
import { CountWidget } from "../widgets/count_widget";
import { ChartWidget } from "../widgets/chart_widget";
import { ParticipationsChartWidget } from "../widgets/participations_chart_widget";
import { ApiStatsChartWidget } from "../widgets/api_stats_chart_widget";
import { OutcomesChartWidget } from "../widgets/outcomes_chart_widget";
import { create_widget, create_chart_widget } from "../widgets/widget_helpers";
import {ParticipationsLastWidget} from "../widgets/participations_last_widget"

export default class extends Controller {
  async connect() {
    let grid = GridStack.init();
    await create_widget(0, 0, 2, 2, '/dashboard_data/example-text', TextWidget, grid);
    await create_widget(2, 0, 2, 2, '/dashboard_data/example-image', ImageWidget, grid);
    await create_widget(4, 0, 1, 1, '/dashboard_data/participation-count', CountWidget, grid);
    await create_chart_widget(5, 0, 3, 2, '/dashboard_data/users-per-role',
        ChartWidget, grid, 'pie_chart');

    await create_chart_widget(0, 2, 8, 4, '/dashboard_data/participations-per-date',
      ParticipationsChartWidget, grid, 'column_chart');
    await create_chart_widget(0, 2, 8, 4, '/dashboard_data/api_visits_per_client_per_month',
      ApiStatsChartWidget, grid, 'column_chart');
    await create_widget(0, 6, 8, 3, '/dashboard_data/latest-participations', ParticipationsLastWidget, grid);
    await create_chart_widget(0, 2, 8, 4, '/dashboard_data/outcomes_per_value',
      OutcomesChartWidget, grid, 'column_chart');
  }
}
