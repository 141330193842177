import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input-participation"
export default class extends Controller {

  static targets = ["root"]

  addParticipation({ detail: { content } }) {
    let participations = content['participations']
    for (let i = 0; i < participations.length; ++i) {
      let participation = participations[i]
      this.rootTarget.innerHTML += ` <div class="ar-table-row">
                              <div class="ar-table-cell">${ participation.id }</div>
                              <div class="ar-table-cell">${ participation?.project?.name || '-' }</div>
                              <div class="ar-table-cell">${ content.id }</div>
                              <div class="ar-table-cell">${ participation?.company?.name || '-' }</div>
                              <div class="ar-table-cell">${ participation.questionnaire.reference }</div>
                              <div class="ar-table-cell">${ participation.questionnaire.version }</div>
                              <div class="ar-table-cell">${ participation['created_at'] }</div>
                              <div class="ar-table-cell">${ participation.client_application.name }</div>
                              <div class="ar-table-cell">${ participation['identifier'] }</div>
                              <div class="ar-table-cell d-flex justify-content-center">
                                <div class="mx-3">
                                  <input class="form-check-input" type="checkbox" checked="checked" name="participations[]" id=${"participation_#{participation.id}"} value="${participation.id}">
                                </div>
                              </div
                            `
    }
  }
}