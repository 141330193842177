import { Widget } from './widget'

export class ImageWidget extends Widget {
    constructor(x, y, w, h, source) {
        super(x, y, w, h, source);
    }

    content() {
        let html = this.wrapper(`<img src="${this.data.source}" class="img-fluid" alt="Responsive image">`, false);
        return html;
    }
}