import {Widget} from './widget'
import {ChartHelpers} from "./chart_helpers";

export class OutcomesChartWidget extends Widget {
    constructor(x, y, w, h, source) {
        super(x, y, w, h, source);
    }

    content() {
        const helpers = new ChartHelpers(this.source, this.data.id)
        const selectCssClass = 'outcomes-per-value-dropdowns'
        let dropdownElements = ''
        Object.keys(this.data.filters_data).forEach((filterName) => {
            if (filterName === 'outcome_name') {
                dropdownElements += helpers.dropdown(filterName, this.data.filters_data,
                  selectCssClass, { triggerFilter: filterName, filtersToBeReset: 'questionnaire,company,project'});
            } else {
                dropdownElements += helpers.dropdown(filterName, this.data.filters_data, selectCssClass);
            }
        })
        let html = this.wrapper(`
            <div class="d-flex my-3 justify-content-end">
              ${dropdownElements}
            </div>
            <div id="${this.data.id}" class="chart-widget h-75"></div>
        `, true);
        return html;
    }
}
