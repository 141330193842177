import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-participation"
export default class extends Controller {
  action(event) {
    fetch(`/data/participations/${event.target.value}`, { headers: { accept: "application/json" } })
    .then(response => response.json())
    .then((data) => {
      this.dispatch('selectedParticipation', {detail: {content: data}})
    }
    )
  }
}
