import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input-company"
export default class extends Controller {

  static targets = ["input", "label"]

  static values = { field: String }

  replaceData({ detail: { content } }) {
    let value = content[this.fieldValue]
    if (value) {
      this.labelTarget.innerHTML = value
      this.inputTarget.value = value
    }
    else {
      this.labelTarget.innerHTML = ' - '
    }

  }
}
