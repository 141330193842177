export class Widget {
    constructor(x, y, w, h, source) {
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
        this.source = source;
        this.data = {};
    }

    async fetch_data() {
        await fetch(this.source).then(response => {return response.json()}).then((fetched_data) => {
            this.data = fetched_data;
        })
    }

    change_dimensions(x, y, w, h) {
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
    }

    wrapper(content, header) {
        let header_html = (header === true) ? `<div class="card-header"> <h5 class="font-weight-bold">${this.data.title}</h5></div>` : '';
        let html = `
            <div class="widget-border border rounded shadow bg-white">
                 ${header_html}
                <div class="widget-content mt-3 h-100">
                ${content}   
                </div>
            </div>
       `;
        return html
    }
}